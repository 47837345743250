.swift-up-text > span {
    display: inline-block;
    animation: fadeInUp 1.5s ease;
    animation-fill-mode: both;
    opacity: 0;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }