
.sticky-parent{
    height: 450vh;
  }
  .sticky{
    position: sticky;
    top: 0px;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .dim{
    display: flex;
    min-width: 30vw;
    height: 100vh;
    align-items: center;
    justify-content: space-around;
  }
.locoImg { 
    height: 70vh;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    aspect-ratio: 1/2;
  }
 
  .locoImgDiv{
    width:100% ;
    height:100%;
  }
  .locoImgDiv > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(0.9);
    transition: 1s;
  } 
  .locoImg:hover{
    cursor: pointer;
  }
  .locoImg:hover .locoImgDiv >img {
    transform: scale(1);
    transition: 1s; 
  }
  .locoTextDiv{
    position: absolute;
    width: 100%;
    height: fit-content;
    left: 0;
    bottom: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transform: scale(0.5);
    opacity: 0;
    color: white;
    transition: 1s;
  }
  .locoTextDivtext{
    font-size: 95px;
    line-height: 80px;
    text-shadow: whitesmoke 3px 3px 10px;
  }
  .locoImg:hover .locoTextDiv{
    transform: scale(1.1);
    opacity: 1;
    transition: 1s; 
    color: white;
  }
  
  .horizontal{
    display: flex;
  }

  @media(max-width:1400px){ 
    .dim{
      display: flex;
      min-width: 35vw;
      height: 100vh;
      align-items: center;
      justify-content: space-around;
    }
  .locoImg { 
      height: 80vh;
      width: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      aspect-ratio: 1/2;
    }
   
  }
  @media(max-width:992px){ 
    .dim{
      display: flex;
      min-width: 50vw;
      height: 100vh;
      align-items: center;
      justify-content: space-around;
    }
  .locoImg { 
      height: 70vh;
      width: 350px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      aspect-ratio: 1/2;
    }
    .locoTextDivtext{
      font-size: 70px;
      line-height: 70px;
    }
  }
  @media(max-width:768px){
    .dim{
        display: flex;
        min-width: 350px; 
        height: 100vh;
        align-items: center;
        justify-content: center;
      }
      .locoTextDivtext{
        font-size: 70px;
        line-height: 70px;
      }
      .locoImg { 
        height: 70vh;
        width: 340px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        aspect-ratio: 1/2;
      }
  }
  