.slide {
  position: relative;  
  height: 100vh;
}
.slide > div{
    background-position: right center;
    background-repeat: no-repeat;
    background-size: auto 80vh;
    height: 100%;  
    align-items: center; 
}
.bigText{  
  font-weight: regular;
  font-family: naibo; 
}
.slide-content {
  font-size: 24px;
}
.slick-dots {
  position: absolute;
  top: 15%;
  left: 30px;
  background: transparent;
  width: fit-content !important;
  height: fit-content;
}

.slick-dots ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 10px;
}
.custom-dot {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background: transparent;
  color: #333;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.slick-dots li.slick-active .custom-dot {
  background: transparent;
  color: #56D6FF;
  border : 1px solid #56D6FF;  
}
.homeSliderText{
  font-size: 100px;
}
.habDiv{
  position: relative;
}
.habImg{
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(0deg);
  transition: 0s;
  opacity: 0; 
  width: 320px; 
}
.habImg4{ 
  opacity: 1; 
  position: relative;
}

 

.habImg4.active{ 
  transform: rotate(0deg); 
  transition: 1s; 
  opacity: 1; 
  transform: scale(1);

}
.habImg3.active{ 
  transform: rotate(-10deg);
  transition: 1s; 
  opacity: 1; 
}
.habImg2.active{ 
  transform: rotate(10deg);  
  transition: 1s; 
  opacity: 1; 
}
.habImg1.active{ 
  transform: rotate(-5deg); 
  transition: 1s; 
  opacity: 1; 
}

.eyewearDumy{
  height: 100px;
}
.catagoryButton{
  background-color : #E7F8FF;
  color: black;
  padding: 10px 20px;
  text-wrap: nowrap;
}
.catagoryButton:hover{
  background-color: #4340A4;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  text-wrap: nowrap;


}
.imgEffectTop{
  clip-path: inset(0 0 100% 0); 
  transition: 2s;
}

.imgEffectTop.active{
  clip-path: inset(0 0 0 0); 
  transition: 2s;
}

.imgEffectBottom{
  clip-path: inset(100% 0 0 0); 
  transition: 2s;
}

.imgEffectBottom.active{
  clip-path: inset(0 0 0 0); 
  transition: 2s;
}


.imgEffectLeft{
  clip-path: inset(0 100% 0 0); 
  transition: 2s;
}

.imgEffectLeft.active{
  clip-path: inset(0 0 0 0); 
  transition: 2s;
}


.imgEffectRight{
  clip-path: inset(0 0 0 100%); 
  transition: 2s;
}

.imgEffectRight.active{
  clip-path: inset(0 0 0 0); 
  transition: 2s;
}



 
.ourBranchImg{
  overflow: hidden;
}
.ourBranchImg > img{
  transform: scale(1); 
  transition: 1.5s;
  width: 100%;
  height: 100% ;
}

.ourBranchImg:hover > img{
  transform: scale(1.1); 
  transition: 1.5s;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.branchSection{
  position: relative;
  height: 100vh;
  /* background-image: url('./images/ourBranch.jpg'); */
  background-position: center;
  background-size: cover;
  -webkit-animation: back 10s infinite; /* Safari 4+ */
  -moz-animation:    back 10s infinite; /* Fx 5+ */
  -o-animation:      back 10s infinite; /* Opera 12+ */
  animation:         back 10s infinite; /* IE 10+, Fx 29+ */
  
}
@keyframes back {
  0% {
    background-image: url("./images/shop1.jpg");
  }
  50% {
    background-image: url("./images/shop2.jpg");
  }
  100% {
    background-image: url("./images/shop3.jpg");
  }  
}
@media(max-width:1690px){
  .homeSliderText{
    font-size: 80px;
  }
}

@media(max-width:1500px){
  .homeSliderText{
    font-size: 70px;
  }
}

@media(max-width:1320px){
  .homeSliderText{
    font-size: 60px;
  }
}


@media(max-width:992px){
  .eyewearDumy{
    height: 0px;
  }
  .slide > div{
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto 50vh;
    height: 100%;  
    align-items: center; 
}
  
}

@media(max-width:768px){
  .homeSliderText{
    font-size: 48px;
  }
  .slide {
    position: relative;  
    height: 70vh;
  }
  .slide > div{
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto 30vh;
    height: 100%;  
    align-items: center; 
}
.branchSection{
  position: relative;
  height: 150vh;
  /* background-image: url('./images/ourBranch.jpg'); */
  background-position: center;
  background-size: 200% ;
  -webkit-animation: back 10s infinite; /* Safari 4+ */
  -moz-animation:    back 10s infinite; /* Fx 5+ */
  -o-animation:      back 10s infinite; /* Opera 12+ */
  animation:         back 10s infinite; /* IE 10+, Fx 29+ */
  
}
  
}

@media(max-width:576px){
  .homeSliderText{
    font-size: 30px;
  }
  .habImg{
    position: absolute;
    top: 0;
    left: 5%;
    transform: rotate(0deg);
    transition: 0s;
    opacity: 0; 
    width: 280px; 
  }
  .habImg4{ 
    opacity: 1; 
    position: relative;
  }
  
   
  
  .habImg4.active{ 
    transform: rotate(0deg); 
    transition: 1s; 
    opacity: 1; 
    transform: scale(1);
  
  }
  .habImg3.active{ 
    transform: rotate(-10deg);
    transition: 1s; 
    opacity: 1; 
  }
  .habImg2.active{ 
    transform: rotate(10deg);  
    transition: 1s; 
    opacity: 1; 
  }
  .habImg1.active{ 
    transform: rotate(-5deg); 
    transition: 1s; 
    opacity: 1; 
  }
}
