.App {
  text-align: center;
  /* overflow-x: hidden; */
}
.whatsapp{
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #25D366;
  font-size: 50px;
  z-index: 999;
  cursor: pointer;
}
.pcNav {
  display: block;
}
.mobileNav {
  display: none;
}
.navbarMain {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  height: 70px;
  background-color: white;
}

.navbarMain--hidden {
  transform: translateY(-70px);
  transition: 1.5s;
}
/* text */
.ourBrandText {
  font-size: 250px;
  line-height: 280px;
  color: #d8f5ff;
  text-wrap: nowrap;
  font-family: "Playfair Display", serif;
  overflow: hidden;
  position: relative;
  top: -30px;
}
.naibofaimly {
  font-family: "Playfair Display", serif;
}
.chashmaMain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.chashma {
  position: relative;
  left: -10%;
  width: 50%;
}
.contactBg { 
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 80vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-position: center;
  background-size: cover;
  -webkit-animation: back 10s infinite; /* Safari 4+ */
  -moz-animation:    back 10s infinite; /* Fx 5+ */
  -o-animation:      back 10s infinite; /* Opera 12+ */
  animation:         back 10s infinite; /* IE 10+, Fx 29+ */
}

 
@keyframes back {
  0% {
    background-image: url("./components/images/shop1.jpg");
  }
  50% {
    background-image: url("./components/images/shop2.jpg");
  }
  100% {
    background-image: url("./components/images/shop3.jpg");
  }  
}
.servicesImg {
  filter: grayscale(100%) sepia(50%) hue-rotate(190deg) saturate(500%);
 transition:0.5s !important;
}
.servicesImg:hover {
  filter: grayscale(0%);
 transition:0.5s !important;
}


@media (max-width: 1600px) {
  .ourBrandText {
    font-size: 195px;
    line-height: 220px;
    color: #d8f5ff;
    text-wrap: nowrap;
    font-family: "Playfair Display", serif;
    overflow: hidden;
    position: relative;
    top: -25px;
  }
}

@media (max-width: 1200px) {
  .ourBrandText {
    font-size: 180px;
    line-height: 150px;
    color: #d8f5ff;
    text-wrap: nowrap;
    font-family: "Playfair Display", serif;
    overflow: hidden;
    position: relative;
    top: -20px;
  }
  .contactBg { 
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 80vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .navbarMain--hidden {
    transform: translateY(0px);
    transition: 1.5s;
  }
  .pcNav {
    display: none;
  }
  .mobileNav {
    display: block;
  }
  .ourBrandText {
    font-size: 120px;
    line-height: 120px;
    color: #d8f5ff;
    text-wrap: nowrap;
    font-family: "Playfair Display", serif;
    overflow: hidden;
    position: relative;
    top: -20px;
  }
  .chashma {
    position: relative;
    left: -10%;
    width: 50%;
    top: 100px;
  }
  .contactBg {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 80vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .ourBrandText {
    font-size: 80px;
    line-height: 80px;
    color: #d8f5ff;
    text-wrap: nowrap;
    font-family: "Playfair Display", serif;
    overflow: hidden;
    position: relative;
    top: -15px;
  }
  .contactBg {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 80vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .ourBrandText {
    font-size: 60px;
    line-height: 50px;
    color: #d8f5ff;
    text-wrap: nowrap;
    font-family: "Playfair Display", serif;
    overflow: hidden;
    position: relative;
    top: -8px;
  }
  .chashmaMain {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .chashma {
    position: relative;
    left: 0%;
    width: 60%;
    top: -100px;
  }
  .contactBg {
 
    background-repeat: no-repeat;
    background-size: 150% 100%;
    background-position: center;
    height: 60vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
