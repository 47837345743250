 
/* right to left  */
@keyframes rLscroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7)); }
}

.rLslider {
	background: #D8F5FF;
	/* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
	height: auto;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.rLslider::before,
.rLslider::after {
	background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	content: "";
	height: auto;
	position: absolute;
	width: 200px;
	z-index: 2;
}

.rLslider::after {
	right: 0;
	top: 0;
	transform: rotateZ(180deg);
}

.rLslider::before {
	left: 0;
	top: 0;
}

.rLslide-track {
	animation: rLscroll 70s linear infinite;
	display: flex;
    gap: 20px;
	width: calc(250px * 20);
    /* background:#E7F8FF;  */
}

.rLslide {
	height:auto;
	width: 500px;
    border: 0.5px solid #56D6FF;
    border-radius: 15px;
    background: white;
    padding: 15px;
    text-align: left;
}

/* left to right */

@keyframes lRscroll {
    0% { transform: translateX(calc(-250px * 7)); }
	100% { transform: translateX(0); }
}

.lRslider {
	background: #D8F5FF;
	/* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
	height: auto;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.lRslider::before,
.lRslider::after {
	background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	content: "";
	height: auto;
	position: absolute;
	width: 200px;
	z-index: 2;
}

.lRslider::after {
	right: 0;
	top: 0;
	transform: rotateZ(180deg);
}

.lRslider::before {
	left: 0;
	top: 0;
}

.lRslide-track {
	animation: lRscroll 70s linear infinite;
	display: flex;
    gap: 20px;
	width: calc(250px * 20);
    /* background:#E7F8FF;  */
}

.lRslide {
	height:auto;
	width: 500px;
    border: 0.5px solid #56D6FF;
    border-radius: 15px;
    background: white;
    padding: 15px;
    text-align: left;
}

.socialIconF > p{
    font-size: 28px;
    color: #D1D1D1;  
    transition: 0.5s;

}

.socialIconF > p:hover{
    font-size: 28px;
    color: #4340A4;  
    cursor: pointer;
    transition: 0.5s;
}
@media(max-width:576px){
    .rLslide {
        height:auto;
        width: 320px;
        border: 0.5px solid #56D6FF;
        border-radius: 15px;
        background: white;
        padding: 15px;
        text-align: left;
    }
    .lRslide {
        height:auto;
        width: 320px;
        border: 0.5px solid #56D6FF;
        border-radius: 15px;
        background: white;
        padding: 15px;
        text-align: left;
    } 
}
