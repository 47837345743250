 .button {
    display: inline-block;
    gap: 10px;
    padding: 10px 15px; 
    color: black;   
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: none;
    border-left: 3px solid #56D6FF;
    font-weight: 500;
    background: transparent;
  }
  
  .button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:transparent; /* Replace this with your desired color */ 
    z-index: -2;
    
  }
  
  .button:before {
    content: '';
    position: absolute;
    bottom: 0; 
    left: 0;
    width: 0%;
    height: 100%;
    background-color: black; /* Adjusted color, replace with darkened version of the main color if needed */
    transition: all 0.3s;
     color: white;
    z-index: -1;
  }
  
  .button:hover {
    color: #fff;
  }
  
  .button:hover:before {
    width: 100%;
  }

/* button white */

  .buttonw {
    display: inline-block;
    gap: 10px;
    padding: 10px 15px; 
    color: white;   
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: none;
    border-left: 3px solid #56D6FF;
    font-weight: 500;
    background: transparent;
  }
  
  .buttonw:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:transparent; /* Replace this with your desired color */ 
    z-index: -2;
    
  }
  
  .buttonw:before {
    content: '';
    position: absolute;
    bottom: 0; 
    left: 0;
    width: 0%;
    height: 100%;
    background-color: white; /* Adjusted color, replace with darkened version of the main color if needed */
    transition: all 0.3s;
     color: black;
    z-index: -1;
  }
  
  .buttonw:hover {
    color: #000000;
  }
  
  .buttonw:hover:before {
    width: 100%;
  }
  
  