@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
 
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
 
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
}
* p{
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.playfair{
  font-family: "Playfair Display", serif;
}
.tJustify{
  text-align: justify;
}



/* input fild  */ 
 .form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid white;
  outline: 0; 
  color: white;
  padding: 5px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  cursor: text;
  top:20px;

}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s; 
  color: white;
  font-size: 13px;

}

.form__field:focus {
  padding-bottom: 2px;
  font-weight: 500;
  border-width: 2px;
  border-image: linear-gradient(to right, rgb(0, 204, 255),rgb(43, 230, 255),rgb(109, 224, 253),rgb(158, 244, 255));
  border-image-slice: 1;
}

/* reset input */
.form__field:required, .form__field:invalid {
  box-shadow: none;
}